import { useAuthStore } from '@/stores/auth'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import type { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw | any> = [
  {
    path: '',
    beforeEnter: async ({ fullPath }: any) => {
      const { checkAuth } = useAuthStore()
      const loggedIn = await checkAuth()
      return loggedIn || { name: 'login', query: { redirectTo: fullPath } }
    },
    component: () => import('@/views/RootView.vue'),
    children: [
      {
        path: '',
        redirect: '/home',
        component: () => import('@/views/TabsView.vue'),
        children: [
          { path: 'home', name: 'home', component: () => import('@/views/HomeView.vue') },

          {
            path: '/docs',
            name: 'docs',
            component: () => import('@/views/DocumentsView.vue')
          },
          {
            path: '/settings',
            component: () => import('@/views/SettingsView.vue')
          },

          {
            path: '/connections',
            component: () => import('@/views/ConnectionsView.vue')
          }
        ]
      },
      { path: '/docs/:docId', component: () => import('@/views/DocumentView.vue') },
      { path: '/share/:shareId', name: 'share', component: () => import('@/views/ShareView.vue') },
      { path: '/task/:taskId', name: 'task', component: () => import('@/views/TaskView.vue') },
      {
        path: '/new-connection/:inviteId',
        component: () => import('@/views/InviteView.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: async () => {
      const { checkAuth } = useAuthStore()
      const loggedIn = await checkAuth()
      return !loggedIn || { path: '' }
    },
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/portal/:taskId',
    name: 'singleTask',
    component: () => import('@/views/PortalTaskVue.vue')
  },
  {
    path: '/invite/:inviteId',
    name: 'invite',
    component: () => import('@/views/PortalTaskVue.vue')
  },
  {
    path: '/magiclink',
    name: 'magicLink',
    component: () => import('@/views/MagicLinkView.vue')
  }
]

const router = createRouter({
  // Use: createWebHistory(process.env.BASE_URL) in your app
  history: createWebHistory(),
  routes
})

export default router
