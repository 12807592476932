<template>
  <DialogPanel
    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-xl"
  >
    <ModalHeader title="Add document" @close="$emit('dismiss')" />

    <div class="p-4 sm:p-6">
      <InfoNote class="mb-4">
        <b>These pages must all belong to the same document.</b> Make sure each page is the right
        orientation and easily readable.
      </InfoNote>

      <ul
        ref="parent"
        role="list"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-4 xl:gap-x-6"
      >
        <li
          v-for="({ previewUrl, original }, index) in pages"
          :key="previewUrl"
          class="relative"
          :data-test-id="`page-editor-modal-page-panel-${index}`"
        >
          <span class="isolate flex">
            <div
              type="button"
              class="flex-1 relative -ml-px inline-flex items-center rounded-tl-md px-3 py-2 text-sm font-semibold text-gray-900"
            >
              Page {{ index + 1 }}
            </div>
            <button
              type="button"
              @click="pages.length > 1 && pages.splice(index, 1)"
              class="relative -ml-px inline-flex items-center rounded-tr-md px-3 py-2 text-sm font-semibold text-gray-900"
              :data-test-id="`page-editor-modal-delete-button-${index}`"
            >
              <XMarkIcon class="h-5 w-5" />
            </button>
          </span>
          <div class="bg-gray-300 border border-gray-300 rounded-md overflow-hidden">
            <img
              :src="previewUrl"
              alt=""
              class="aspect-square block w-full object-contain pointer-events-none group-hover:opacity-75"
              :data-test-id="`${original.name}`"
            />
            <span class="isolate flex divide-x border-t border-1 border-gray-300">
              <button
                type="button"
                @click="movePage(index, index - 1)"
                class="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
                data-test-id="page-editor-modal-page-left-button"
              >
                <ChevronLeftIcon class="h-5 w-5" />
              </button>
              <button
                @click="editImage(index)"
                type="button"
                class="flex-1 relative -ml-px inline-flex items-center justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
                :data-test-id="`page-editor-modal-edit-button-${index}`"
              >
                crop/rotate
              </button>
              <button
                @click="movePage(index, index + 1)"
                type="button"
                class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
                data-test-id="page-editor-modal-page-right-button"
              >
                <ChevronRightIcon class="h-5 w-5" />
              </button>
            </span>
          </div>
        </li>
        <li v-if="filesLoading" class="relative">
          <span class="isolate flex">
            <div
              type="button"
              class="flex-1 relative -ml-px inline-flex items-center rounded-tl-md px-3 py-2 text-sm font-semibold text-gray-900"
            >
              Loading...
            </div>
            <button
              type="button"
              class="relative -ml-px inline-flex items-center rounded-tr-md px-3 py-2 text-sm font-semibold text-gray-900"
            >
              <XMarkIcon class="h-5 w-5" />
            </button>
          </span>
          <div class="bg-gray-300 border border-gray-300 rounded-md overflow-hidden">
            <div class="aspect-square flex justify-center items-center">
              <ion-spinner></ion-spinner>
            </div>
            <span class="isolate flex divide-x border-t border-1 border-gray-300">
              <button
                type="button"
                class="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
              >
                <ChevronLeftIcon class="h-5 w-5" />
              </button>
              <button
                type="button"
                class="flex-1 relative -ml-px inline-flex items-center justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
              >
                edit
              </button>
              <button
                type="button"
                class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10"
              >
                <ChevronRightIcon class="h-5 w-5" />
              </button>
            </span>
          </div>
        </li>
      </ul>
      <div class="grid grid-cols-2 gap-x-4 mt-10">
        <AppButton
          color="light"
          @clicked="($refs.fileInput as HTMLInputElement).click()"
          :full="true"
          >Add page</AppButton
        >
        <input
          ref="fileInput"
          type="file"
          accept="image/*,.pdf"
          class="hidden"
          @change="
            ($event: any) => {
              !!$event.target.files && addPages([...$event.target.files])
              $event.target.value = null
            }
          "
          data-test-id="page-editor-modal-add-pages-input"
        />
        <AppButton
          :disabled="!pages.length"
          data-test-id="page-editor-modal-next-button"
          @clicked="!!doc ? uploadDocEdits() : createNewDoc()"
          >Next</AppButton
        >
      </div>
    </div>
  </DialogPanel>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { openDefaultEditor } from '@pqina/pintura'
import type { PinturaDefaultImageWriterResult } from '@pqina/pintura'
import type { Ref } from 'vue'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { IonSpinner } from '@ionic/vue'
import { DialogPanel } from '@headlessui/vue'
import { useModalStore } from '@/stores/modal'
import { supabase } from '@/lib/supabaseClient'
import type { CreateDocumentPage, DocumentData } from '@/types/types'
import ModalHeader from '@/components/ModalHeader.vue'
import AppButton from '@/components/AppButton.vue'
import { useQueryClient } from '@tanstack/vue-query'
import createDoc from '@/helpers/createDoc'
import formatUploadedDoc from '@/helpers/formatUploadedDoc'
import InfoNote from './InfoNote.vue'

const { presentLoader, dismissLoader, presentAlert } = useModalStore()

const queryClient = useQueryClient()

const props = defineProps<{ files?: File[]; doc?: DocumentData }>()
const emit = defineEmits<{
  (
    event: 'dismiss',
    completeDocumentModalProps?: { localImageUrls: string[]; doc: DocumentData }
  ): void
}>()
const pages: Ref<CreateDocumentPage[]> = ref([])
const [parent, enable] = useAutoAnimate()
const filesLoading = ref(false)
const originalFiles: { file: File; skipUpload: boolean }[] = []

onMounted(async () => {
  enable(false)
  let files = props.files
  if (!files) {
    filesLoading.value = true
    files = await Promise.all(
      props.doc!.originalFilePaths!.map((path) =>
        supabase.storage
          .from('documents')
          .download(path)
          .then(({ data }) => new File([data!], path, { type: data?.type }))
      )
    )
    addPages(files, true)
  } else {
    addPages(files)
  }
})

const createNewDoc = async () => {
  presentLoader()
  const formattedDoc = await createDoc(originalFiles, pages.value)
  dismissLoader()
  if (!!formattedDoc) {
    queryClient.invalidateQueries({ queryKey: ['documents'] })
    emit('dismiss', {
      localImageUrls: pages.value.map((page) => page.previewUrl),
      doc: formattedDoc!
    })
  }
}

const uploadDocEdits = async () => {
  presentLoader()
  const formattedDoc = await formatUploadedDoc(props.doc!, originalFiles, pages.value)
  dismissLoader()
  if (!!formattedDoc) {
    queryClient.invalidateQueries({ queryKey: ['documents'] })
    emit('dismiss', {
      localImageUrls: pages.value.map((page) => page.previewUrl),
      doc: formattedDoc!
    })
  }
}

const addPages = async (files: File[], skipUpload = false) => {
  const filePages = await Promise.all(
    files.map(async (file) => {
      originalFiles.push({ file, skipUpload })
      if (file.type === 'application/pdf') {
        filesLoading.value = true
        enable(false)
        const { default: pdfToImages } = await import('@/helpers/pdfToImages')
        const imageFiles = await pdfToImages(file)
        return imageFiles.map((file) => ({
          original: file,
          edited: file,
          imageState: null,
          previewUrl: URL.createObjectURL(file)
        }))
      } else {
        return {
          original: file,
          edited: file,
          imageState: null,
          previewUrl: URL.createObjectURL(file)
        }
      }
    })
  ).catch(() => {
    emit('dismiss')
    presentAlert({
      title: `Sorry, we can't process this document`,
      message: `The file might be corrupt or your browser may be outdated - you could try uploading it as an image instead. <a target="_blank" class="text-blue-600 font-semibold" href="https://profile-crewdentials.tawk.help/article/supported-document-types">Find out more</a>`
    })
  })

  if (!!filePages) {
    // Make animation work smoothly
    const [page1, ...otherPages] = filePages.flat()
    filesLoading.value = false
    pages.value.push(page1)
    setTimeout(() => {
      enable(true)
      pages.value.push(...otherPages)
    }, 0)
  }
}

const movePage = (fromIndex: number, toIndex: number) => {
  if (toIndex > -1 && toIndex < pages.value.length) {
    const item = pages.value.splice(fromIndex, 1)
    pages.value.splice(toIndex, 0, item[0])
  }
}

const editImage = (index: number) => {
  const { original, imageState } = pages.value[index]
  const editor = openDefaultEditor({
    src: original,
    imageState
  })
  editor.on(
    'process',
    (event: PinturaDefaultImageWriterResult) =>
      (pages.value[index] = {
        original,
        edited: event.dest,
        previewUrl: URL.createObjectURL(event.dest),
        imageState: event.imageState
      })
  )
}
</script>
